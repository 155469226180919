import logo from './img/logo.png';
import discordLogo from './img/discord-logo.svg'; // Replace 'discord-logo.png' with the actual file name of the Discord logo
import './App.css';
import CollapsibleNavbar from './Components/Navigation/CollapsibleNavbar';

function App() {
  return (
    <div className="App">
      <CollapsibleNavbar />
      <header className="App-header">
      <div className="logo-container">
        <img src={logo} className="App-logo" alt="logo" />
 <p>Join Our Discord</p>
        <a
          className="App-link"
          href="https://discord.gg/onixrp"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={discordLogo} className="Discord-logo" alt="Discord Logo" />
        </a>
        </div>

      </header>
    </div>
  );
}

export default App;

// CollapsibleNavbar.js

import React, { useState } from 'react';
import './CollapsibleNavbar.css';

function CollapsibleNavbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`CollapsibleNavbar ${isNavOpen ? 'open' : ''}`}>
      <button className="toggle-button" onClick={toggleNav}>
        &#9776; {/* Hamburger menu icon */}
      </button>
      <ul className={`nav-list ${isNavOpen ? 'open' : ''}`}>
      {/*  <li className="nav-item">
          <a href="/">Home</a>
        </li>
        <li className="nav-item">
          <a href="/about">About</a>
        </li>
        <li className="nav-item">
          <a href="/services">Services</a>
        </li>
        <li className="nav-item">
          <a href="/contact">Contact</a>
        </li> */}
      </ul>
    </div>
  );
}

export default CollapsibleNavbar;
